import RouterAdapter from "@pathwright/web-new/src/routes/RouterAdapterForLegacy"
import AuthContainer, {
  AuthContainerProps
} from "@pathwright/web-new/src/views/auth/AuthContainer"
import { ConnectedSpaceProvider } from "@pathwright/web-new/src/views/space/api/context"

type Props = AuthContainerProps & { useTheme?: boolean }

const RouteElement = ({ useTheme, ...props }: Props) => {
  return (
    <ConnectedSpaceProvider useTheme={useTheme}>
      <div className="chakra-ui-reset-css" style={{ width: "100%" }}>
        <AuthContainer {...props} />
      </div>
    </ConnectedSpaceProvider>
  )
}

// Wrapping with RouterProvider in order for the `unstable_useBlocker` hook to work.
const AdaptedAuthContainer = (props: Props) => {
  return <RouterAdapter element={<RouteElement {...props} />} />
}

export default AdaptedAuthContainer
