import i18n from "i18next"
import capitalize from "lodash/capitalize"
import startCase from "lodash/startCase"
import { useContext, useMemo } from "react"
import {
  I18nContext,
  I18nextProvider,
  initReactI18next,
  useTranslation
} from "react-i18next"
import englishTranslations from "./translations/en/common.json"

const flattenTranslationTree = (translationTree: any) => {
  // convert the translation tree to a flat object with "." delimited keys
  const flatten: any = (obj: any, path = "") => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === "string") {
        return {
          ...acc,
          [path + key]: value
        }
      }

      return {
        ...acc,
        ...flatten(value, path + key + ".")
      }
    }, {})
  }
  return flatten(translationTree)
}

export const getI18n = () => {
  // TODO: make this accept the school translation values and build from there
  // should also lazy load non-english translations
  const resources = {
    en: { translation: flattenTranslationTree(englishTranslations) }
  }
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",
      fallbackLng: "en",
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    })
  return i18n
}

const TranslationsProvider = ({ children }: { children: JSX.Element }) => {
  // TODO: memoize based on school translation.
  const i18n: any = useMemo(getI18n, [])
  // Only provide local I18nContext when not rendered wiithin a parent i18n context.
  return useContext(I18nContext) ? (
    children
  ) : (
    <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
  )
}

export const useScopedTranslation = (prefix?: string) => {
  // this is a custom hook that allows us to use the translation function with a default prefix
  const { t } = useTranslation()

  const localizedFn = (key: string, options = {}) => {
    return prefix ? t(`${prefix}.${key}`, options) : t(key, options)
  }

  const localizedFnWithCapitalize = (key: string, options = {}) =>
    capitalize(localizedFn(key, options))

  const localizedFnWithStartCase = (key: string, options = {}) =>
    startCase(localizedFn(key, options))

  return {
    t: localizedFn,
    tc: localizedFnWithCapitalize,
    tca: localizedFnWithStartCase
  }
}

export default TranslationsProvider
