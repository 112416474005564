import { useEffect, useMemo, useState } from "react"
import { unstable_useBlocker } from "react-router-dom"
import { usePreviousDistinct } from "react-use"
import {
  UserStatusInputType,
  useSpaceContextQueryQuery
} from "../../api/generated"
import { getAuthenticationToken } from "../../lib/utils/auth-token"
import { exhaustiveGuard } from "../../lib/utils/exhaustive-switch"
import { ConnectedAuthProvider, useAuthContext } from "./api/context"
import AuthWrapper, { AuthWrapperProps } from "./components/AuthWrapper"
import { useShouldPromptUserToJoinSpace } from "./join/JoinForm"
import JoinViewContainer, {
  JoinViewContainerProps
} from "./join/JoinViewContainer"
import PasswordResetViewContainer from "./password/reset/PasswordResetViewContainer"
import SignInViewContainer, {
  SignInUserEmailStatusSuccessHandler
} from "./sign-in/SignInContainer"
import SignInAuthRequestViewContainer from "./sign-in/auth-request/SignInAuthRequestViewContainer"
import { AuthRequestResultType } from "./sign-in/auth-request/api/action"
import SignInAuthRequestConfirmViewContainer from "./sign-in/auth-request/confirm/SignInAuthRequestConfirmViewContainer"
import SignInPasswordViewContainer, {
  SignInSuccessHandler
} from "./sign-in/password/SignInPasswordViewContainer"
import SignUpViewContainer from "./sign-up/SignUpViewContainer"
import SignedIn from "./signed-in/components/SignedIn"
import { AuthMode, UserPropertyDefaults } from "./types"
import { AuthIntentKeyWithError } from "./utils/authIntent"
import {
  getAuthMethodForUserStatus,
  getDefaultAuthMethod
} from "./utils/preferredAuthMethod"

const authModes: Record<string, AuthMode> = {
  "/password": "password",
  "/password/reset": "password_reset",
  "/request": "request",
  "/request/confirm": "request_confirm",
  "/sign-up": "signup",
  // Some routes are more precise, like when linking to the sign-in form
  // from the sign-up form.
  "/sign-in/password": "password",
  "/sign-in/request": "request"
}

// Map the possible auth intents to the appropriate auth modes.
// This way, we can ensure the user is returned to the same auth view
// from which they begain an auth intend (currently limited to Global SSO).
// Currently, the only purpose for this behavior is to show the user an auth intent error.
const authIntentErrorMode: Record<AuthIntentKeyWithError, AuthMode> = {
  "sign_in:password": "password",
  "sign_in:without_password": "request",
  sign_up: "signup",
  sign_in_or_sign_up: "signup"
}

type AuthenticateProps = {
  defaultAuthMethod?: AuthMode
}

const Authenticate = ({
  defaultAuthMethod
}: AuthenticateProps): JSX.Element | null => {
  const { authIntent } = useAuthContext()
  // Defer initial `authMode` to the `authIntent.error`, if present.
  const [authMode, setAuthMode] = useState<AuthMode>(
    authIntent?.error
      ? authIntentErrorMode[authIntent.key]
      : defaultAuthMethod || getDefaultAuthMethod
  )
  const [authRequestData, setAuthRequestData] = useState<AuthRequestResultType>(
    { uuid: "" }
  )

  // TODO: extract into some general use hook.
  unstable_useBlocker(({ nextLocation }) => {
    const nextAuthMode = authModes[nextLocation.pathname]
    if (nextAuthMode) setAuthMode(nextAuthMode)
    // Only block routing if we have a mode to switch to.
    return !!nextAuthMode
  })

  const handleSignInAccess: SignInUserEmailStatusSuccessHandler = (value) => {
    // NOTE: the only time when there should be no value is when an error occurs,
    // likely thrown client-side when the user doesn't have permission to move forward
    // with the submitted username/email.
    if (value) {
      // An auth request would have been issued for an email that exists but
      // hasn't been verified.
      if ("uuid" in value) {
        setAuthRequestData(value)
        setAuthMode("request_confirm")
      } else if (value.input_type === UserStatusInputType.Proxy) {
        // All proxied users must enter password.
        setAuthMode("password")
      } else if (value.user_exists && value.email_verified) {
        // At this point, if the email exists, we know that it has been verified.
        // The user must sign in with auth request or password.
        setAuthMode(getAuthMethodForUserStatus(value))
      } else {
        // A user for this email does not exist, so redirect them to the sign up form.
        setAuthMode("signup")
      }
    }
  }

  const handleAuthRequestSuccess = ({ uuid }: AuthRequestResultType) => {
    setAuthRequestData({ uuid })
    setAuthMode("request_confirm")
  }

  // Conditionally call `handleAuthRequestSuccess` when handling auth request.
  const handleSignInSuccess: SignInSuccessHandler = (value) => {
    if (value && "uuid" in value) {
      handleAuthRequestSuccess(value)
    }
  }

  switch (authMode) {
    case "access":
      return <SignInViewContainer onSuccess={handleSignInAccess} />
    case "signup":
      return <SignUpViewContainer />
    case "request":
      return (
        <SignInAuthRequestViewContainer onSuccess={handleAuthRequestSuccess} />
      )
    case "request_confirm":
      return (
        <SignInAuthRequestConfirmViewContainer
          authRequestId={authRequestData.uuid}
          // onSuccess={handleAuthSuccess}
        />
      )
    case "password":
      return <SignInPasswordViewContainer onSuccess={handleSignInSuccess} />
    case "password_reset":
    case "password_reset_confirm":
      return <PasswordResetViewContainer />
    default:
      exhaustiveGuard(authMode)
  }
}

export type AuthProps = {
  defaultAuthMethod?: AuthMode
  // Optionally ensure the user sees the signed in state with "Continue" prompt.
  // The user may not actually need to join, but at least allows parent to defer
  // to JoinedContainer for next action by supplying `onContinue` prompt.
  promptUserToJoinSpace?: boolean
  onComplete?: JoinViewContainerProps["onComplete"]
}

const Auth = ({
  defaultAuthMethod,
  promptUserToJoinSpace,
  onComplete
}: AuthProps) => {
  const shouldPromptUserToJoinSpace =
    useShouldPromptUserToJoinSpace() || promptUserToJoinSpace

  // const { user } = useSpaceContext()
  // NOTE: currently must rely on this watchQuery (used under the hood by useQuery) rather than
  // depend on the SpaceContext provided by the rout ("/") route because all loader queries
  // use client.query (as opposed to client.watchQuery) and will not refetch when client.resetStore
  // is called.
  // Now, you could use useRevalidator().revalidator, but, ideally the *Container components
  // should be independent of routes anyway.
  const { data } = useSpaceContextQueryQuery()
  const user = data?.user
  // Is there already an authenticated user?
  const initialAuthToken = useMemo(getAuthenticationToken, [])
  // Default prevAuthToken to the initialAuthToken if exists.
  // We don't want to to call `onComplete` when the change in users is merely
  // due to the initial result of the `useSpaceContextQueryQuery` completing.
  const prevAuthToken =
    usePreviousDistinct(getAuthenticationToken()) || initialAuthToken

  // Allows for postponing transitioning away from Authenticate after
  // user authenticates until the onComplete handler has finished.
  const [isAwaitingAuth, setIsAwaitingAuth] = useState(!initialAuthToken)

  // When a user authenticates, the flow is complete.
  useEffect(() => {
    async function attemptOnComplete() {
      // Notice we're comparing auth tokens to confirm that the user
      // did in fact authenticate and that the user was not already authenticated.
      if (prevAuthToken !== getAuthenticationToken()) {
        if (user) {
          // Only consider the flow completed after authentication if the user should not
          // be prompted to join the space.
          if (!shouldPromptUserToJoinSpace) await onComplete?.()
          setIsAwaitingAuth(false)
        } else {
          setIsAwaitingAuth(true)
        }
      }
    }

    attemptOnComplete()
  }, [user?.id])

  return isAwaitingAuth ? (
    <Authenticate defaultAuthMethod={defaultAuthMethod} />
  ) : // Explicitly preventing user to join the space?
  promptUserToJoinSpace === false ? (
    <SignedIn size="lg" allowSignOut />
  ) : (
    <JoinViewContainer onComplete={onComplete} />
  )
}

export type AuthContainerProps = AuthProps & {
  inviteToken?: string | null
  userPropertyDefaults?: UserPropertyDefaults
  authWrapperProps?: AuthWrapperProps
  // Override the school.allow_signup field.
  allowSignup?: boolean
  // Override the default behavior of creating a membership for the user
  // when they sign up (or sign in via Global SSO).
  createMembership?: boolean
  // Allow overriding the default auth method.
  defaultAuthMethod?: AuthMode
}

const AuthContainer = ({
  inviteToken,
  userPropertyDefaults,
  authWrapperProps,
  allowSignup,
  createMembership,
  ...passProps
}: AuthContainerProps) => {
  return (
    <ConnectedAuthProvider
      variables={{
        inviteToken
      }}
      userPropertyDefaults={userPropertyDefaults}
      allowSignup={allowSignup}
      createMembership={createMembership}
    >
      <AuthWrapper
        className="AuthContainer"
        m={"0 auto"}
        sx={{
          [".AuthSpaceHeader"]: {
            display: "none"
          },
          // Must prevent the marginal spacing introduce by any VStacks...
          [".AuthSpaceHeader + *"]: {
            marginTop: "0 !important"
          }
        }}
        {...authWrapperProps}
      >
        <Auth {...passProps} />
      </AuthWrapper>
    </ConnectedAuthProvider>
  )
}

export default AuthContainer
